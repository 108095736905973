import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Button, Select, Typography } from '@material-ui/core';

export const MonthlyCostDropDownBlock = styled(Box)`
  ${({ theme: { breakpoints, colors } }) => `
    .MuiAutocomplete-input {
        height: 20px !important;
        border: 2px solid  ${colors.greens.green0};

    }
    ${breakpoints.up('xs')} {

        background-color: ${colors.whites.white2};
        width:100%;
        margin: 20px auto;
        justify-items: center;
        border-radius: 18px;
        background-color: ${colors.whites.white2};
    
        left: 0;
        height: auto;
        display: flex;


        border-radius: 18px;
        padding: 10px 10px 20px 10px;
        flex-direction: column;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
     }

     ${breakpoints.up('sm')} { width:70%; }
        ${breakpoints.up('md')} {
            width: 546px;
            height: 195px;
            position: relative;
            top: 0.5rem;
            left: 1rem;
            z-index: 3; 
        }

        ${breakpoints.up('xl')} {
            position: relative;
            left: 8rem;
            top: -0.5rem;
           

        }
    `}
`;

export const MonthlyCostDropDownLocationBlock = styled(Box)`
  input {
    padding: 9px !important;
  }
  ${({ theme: { breakpoints, colors } }) => `
            display: flex;
            flex-direction: column;
            height: 150px;
            justify-content: space-between;
        ${breakpoints.up('md')} {
            display: flex;
            flex-direction: row;
            padding-top: 1rem;
            height: unset;
            justify-content: unset;
        }

    `}
`;

export const BoldSpan = styled.span`
  font-weight: bold;
  display: block;
  padding-bottom: 3px;
  font-size: 16px;
`;

export const MonthlyCostDropDownLocationTextBlock = styled(Box)`
  ${({ theme: { breakpoints, colors } }) => `
            color: ${colors.greens.green0};
            font-size: 16px;
            font-weight: 500;
            padding-top: 1rem;
        
 
    `}
`;

export const MonthlyCostDropDownLocationInputBlock = styled(Box)`
  ${({ theme: { breakpoints, colors } }) => `
            // width: 309px;
            height: 18px;

            border-radius: 0px;
            padding: auto;
            font-size: 14px;
            font-weight: 500;
            color: ${colors.greens.green0};
        ${breakpoints.up('md')} {
            width: 243px;
            height: 40px;
        }

    `}
`;

export const MonthlyCostDropDownMonthlyTextBlock = styled(Box)`
  ${({ theme: { breakpoints, colors } }) => `
.noValue {
    .MuiInputBase-input::after { 
        content: "Select"; 

    } }
      
    
            color: ${colors.greens.green0};
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            padding-top: 1rem;
            // padding-left: 1rem;
        ${breakpoints.up('md')} {
            padding-left: 1rem;
        }

    `}
`;

export const Sselectbox = styled(Select)`
  border: 2px solid red;
`;

export const MonthlyCostDropDownMonthlyCostInputBlock = styled(Box)`
  width: 100%;
  display: block;

  ${({ theme: { breakpoints, colors } }) => `
            select {
                 width: 100%;
                 height: 40px;
                // border: 1px solid ${colors.greens.green0};
                border-radius: 0px;
                padding: auto;
                color: ${colors.greens.green0};
                font-size: 14px;
          
            }
        ${breakpoints.up('md')} {
            select {
                width: 243px;
                height: 40px;
                padding-left: 0.5rem;
            }
        }

    `}
`;

export const MonthlyCostDropDownButtonBlock = styled(Box)`
  ${({ theme: { breakpoints, colors } }) => `
            padding-top: 1rem;
            
            button {
                &:hover  {    background: ${colors.greens.green0} !important;}
                width: 100%;
                height: 50px;
                border-radius: 25px;
                cursor: pointer;
                background: ${colors.greens.green0};
                color: ${colors.whites.white2};
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
            }
            ${breakpoints.up('sm')} {
                padding-left: 1rem;
            }
        ${breakpoints.up('md')} {
            position: relative;
            margin: auto;
            padding-top: 2rem;
            button {
                width: 204px;
                height: 46px;
            }

        }
    `}
`;
