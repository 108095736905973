import React, { useState } from 'react';
import SearchIcon from '@keaze/web/public/svg/SearchIcon.svg';
import NextLink from 'next/link';
import { MonthlyCosts } from './monthlyCost';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';

import styled from 'styled-components';

const SelectInputStyles = styled(InputBase)`
& > div:focus {
  border-radius: 0 !important;
}
    label {
        margin-top: 1rem;
    }
    .MuiInputBase-input {


        display: flex;
        color:  #003C3B;
        font-size: 16px !important;
        padding: 0 0 0 10px !important;
        align-items: center;
        border: 2px solid #003C3B;
        padding: 1rem 2.6rem 1rem 1.2rem;
        border-radius: 0;
        font-weight: bold;
        font-size: 16px;
        height:  38px !important;
        margin-top: 2px;
    }

    &:focus {
        box-shadow: ' 0px 0px 2px 1px  #00ffab';
    }
}`;

import {
  Sselectbox,
  MonthlyCostDropDownBlock,
  BoldSpan,
  MonthlyCostDropDownButtonBlock,
  MonthlyCostDropDownLocationBlock,
  MonthlyCostDropDownLocationInputBlock,
  MonthlyCostDropDownLocationTextBlock,
  MonthlyCostDropDownMonthlyCostInputBlock,
  MonthlyCostDropDownMonthlyTextBlock,
} from './MonthlyCostDropDown.styles';

import { useQuickSearch } from '@keaze/web/views/searchBlocks/quickSearch/quickSearch.hook';
import { GoogleSearchAutocomplete } from '@keaze/web/components/googleSearchAutocomplete';
import { capitalize } from '@material-ui/core';

const S = {
  Select: styled(Select)`
    #monthlyCostSelect:focus {
      border-radius: 0 !important;
    }
  `,

  SearchButton: styled(Button)`
    font-size: 14px;
  `,

  Dropdown: styled(Select)`
    border-radius: 0;
  `,

  AutoComplete: styled(GoogleSearchAutocomplete)`
    border: 2px solid red !important;
    height: 20px !important;

    input {
      padding: 0;
      border: 1px solid red;
    }
  `,
};

export interface maxMonthlyCost {
  monthlyCost: string;
}

const MonthlyCostDropDown = () => {
  const [state, setState] = useState<maxMonthlyCost>({
    monthlyCost: '',
  });

  const {
    googleSearchValue,
    generatedHref,
    setGoogleSearchValue,
  } = useQuickSearch();

  //   localStorage.setItem("monthlyCost", "")

  const onSelectChange = (event: SelectChangeEvent) => {
    // const values = event.target.value;

    setState({ ...state, monthlyCost: event.target.value });
  };

  const handleClick = () => {
    localStorage.setItem('monthlyCost', state.monthlyCost);
    localStorage.removeItem('defaultText');
    localStorage.removeItem('@keaze/filters');
    location.href = `/search?location=${googleSearchValue}&maxPrice=${state.monthlyCost}`;
    localStorage.setItem(
      '@keaze/sortBy',
      state.monthlyCost !== '' ? '"monthly-spend"' : ''
    );
  };

  return (
    <>
      <MonthlyCostDropDownBlock>
        <MonthlyCostDropDownLocationBlock>
          <MonthlyCostDropDownLocationTextBlock>
            <BoldSpan>Enter a location</BoldSpan>
            <MonthlyCostDropDownLocationInputBlock data-location="home">
              <GoogleSearchAutocomplete
                value={googleSearchValue}
                onChange={(value: any) => setGoogleSearchValue(value)}
              />
            </MonthlyCostDropDownLocationInputBlock>
          </MonthlyCostDropDownLocationTextBlock>
          <MonthlyCostDropDownMonthlyTextBlock>
            <BoldSpan>Monthly cost</BoldSpan>
            <MonthlyCostDropDownMonthlyCostInputBlock
              className={state.monthlyCost ? 'value' : 'noValue'}
            >
              <Select
                input={<SelectInputStyles />}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'center',
                    horizontal: 'center',
                  },
                }}
                sx={{
                  background: 'white',
                  width: '100%',
                  height: '40px',
                  borderRadius: '0',
                }}
                id="monthlyCostSelect"
                value={state.monthlyCost}
                label="Monthly Cost"
                onChange={onSelectChange}
              >
                {MonthlyCosts.map((Cost) => (
                  <MenuItem
                    sx={{ borderBottom: '1px solid #F5F5F5', margin: '0 5px;' }}
                    value={Cost.value}
                    key={Cost.value}
                  >
                    {Cost.text}
                  </MenuItem>
                ))}
              </Select>
            </MonthlyCostDropDownMonthlyCostInputBlock>
          </MonthlyCostDropDownMonthlyTextBlock>
        </MonthlyCostDropDownLocationBlock>
        <MonthlyCostDropDownButtonBlock>
          <S.SearchButton onClick={() => handleClick()}>
            <SearchIcon />
            Search
          </S.SearchButton>
        </MonthlyCostDropDownButtonBlock>
      </MonthlyCostDropDownBlock>
    </>
  );
};

export default MonthlyCostDropDown;
