import * as React from 'react';
import { Box } from '@mui/system';
import MonthlyCostDropDown from '@keaze/web/components/homestepper/MonthlyCostDropDown/MonthlyCostDropDown'
import styled from 'styled-components';
import useWindowDimensions from './windowWidths';

const S = {
  HpContainer: styled(Box)`

  ${({ theme: { breakpoints, colors} }) => `
  ${breakpoints.up('xs')} {
    display: flex;
    flex-direction: column;
  align-items: top;
   }
   ${breakpoints.up('sm')} {
flex-direction: column;
align-items: center;
justify-content: center;
   }
  
  `}
  `, 

  textStyle: styled.h1 `
    ${({ theme: {breakpoints, colors} }) => `

      padding: 0 40px 0 0;
      font-size: 25px;
      font-weight: 100 !important;
      color: ${colors.whites.white1};

      ${breakpoints.up('sm')} {
     
      font-size: 35px;
    
      }
      ${breakpoints.up('md')} {
        margin-top: 60px;
      font-size: 45px;
    
      }
      font-weight: normal;
    `}
  `,


  TextContiner: styled.div `
  ${({ theme: { breakpoints, colors} }) => `
  ${breakpoints.up('xs')} {

    width: 100%;
   }
   ${breakpoints.up('md')} {

    width: 40%;
   }
  `}
`,


  ImageContainer: styled(Box) `
  ${({ theme: { breakpoints, colors} }) => `
 ${breakpoints.up('xs')} {


 
    background-position: center;
     background-size: cover;
     max-height: 250px;
  
 }
   ${breakpoints.up('sm')} { 
max-height: 500px;

   }



   ${breakpoints.up('md')} {
    top: unset;
    width: 60%;
    max-height: 797px;
    border-radius: 0 0 125px 0;
    position: absolute;
   }
  
  `}

  background-repeat: no-repeat;

  background-position: bottom;
  border-radius: 0 0 120px 0;
  object-fit: cover;
  z-index: 2;
  min-height: auto;
  right: 0;

  `,

  absContainer: styled.div `

  ${({ theme: { breakpoints, colors} }) => `


width: 100%;

  ${breakpoints.up('md')} { 

  display: flex;
  position: relative;
  z-index: 1;

  height: 100%;
  align-items: center;
  `}`,

  HpInner: styled(Box)`

  position: relative;
  height: auto;
  display: flex;
  
  ${({ theme: { colors, breakpoints} }) => `
  ${breakpoints.up('md')} {position: relative}
  width: 100%;
  position: static;
  &:before{
    ${breakpoints.up('md')} { height: 90%;}
  content: '';
      position: absolute;
      top: 0;
      right: 0 !important;
      background-color: ${colors.greens.green6} !important;
      right: -16px;
      width: 100vw;
      height: 65%;
      background-color: #fff;
      z-index: -1;
      
  }
  &:after{
    ${breakpoints.up('md')} { height: 90%;}
    content: '';
        position: absolute;
        top: 0;
        left: 0 !important;
        background-color: ${colors.greens.green6} !important;
        right: -16px;
        width: 100vw;
        height: 65%;
        background-color: #fff;
        z-index: -1;
     
    }
    `}
  `

}

interface Props {
  data: Record<string, any>;
}

export const HomeHeroFirst = (props: Props) => {
  const {height, width} = useWindowDimensions();

  return (
      <S.HpContainer sx={{
        height: height !== undefined && height >= 500 && width !== undefined && width >= 900  ? ' calc(100vh - 148px)' : 'auto',
      }}>
        <S.HpInner>
          <S.absContainer>
            <S.TextContiner>
              <S.textStyle>
                <div dangerouslySetInnerHTML={{__html: props.data.elements.hero.content ?? ''}}/>
              </S.textStyle>
              { width !== undefined && width >= 900 ?  <MonthlyCostDropDown/> : ''}
            </S.TextContiner>
            <S.ImageContainer sx={{
              height: height !== undefined && height >= 850 && width !== undefined && width >= 770 ? '700px' : height !== undefined && height >= 600 && width !== undefined && width >= 770 ? '480px' : '440px',
              backgroundImage: `url(${props.data.elements.hero.image ?? '/images/HomeStepper/HomepageHeroDesktop.png'})`
            }}></S.ImageContainer>
             { width !== undefined && width <= 900 ?  <MonthlyCostDropDown/>  : ''}
          </S.absContainer>
        </S.HpInner>
       
      </S.HpContainer>
  )
}

