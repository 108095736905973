import { Data } from './data';

export const MonthlyCosts: Data[] = [
  {
    value: 800,
    text: '£800',
  },
  {
    value: 900,
    text: '£900',
  },
  {
    value: 1000,
    text: '£1,000',
  },
  {
    value: 1100,
    text: '£1,100',
  },
  {
    value: 1200,
    text: '£1,200',
  },
  {
    value: 1300,
    text: '£1,300',
  },
  {
    value: 1400,
    text: '£1,400',
  },
  {
    value: 1500,
    text: '£1,500',
  },
  {
    value: 1600,
    text: '£1,600',
  },
  {
    value: 1700,
    text: '£1,700',
  },
  {
    value: 1800,
    text: '£1,800',
  },
  {
    value: 1900,
    text: '£1,900',
  },
  {
    value: 2000,
    text: '£2,000',
  },
  {
    value: 3000,
    text: '£3,000',
  },
  {
    value: 4000,
    text: '£4,000',
  },
];
