import React, { FC } from 'react';
import { GetStaticProps } from 'next';

import { Head } from '@keaze/web/components/head';
import {
  FeaturedHousingProvider,
  SchemeArea,
  Listing,
  RequestError,
} from '../common/interfaces';
import { HomeHeroFirst } from '@keaze/web/views/homeHero/homeHeroFirst';
import CmsApi from "@keaze/web/utils/cmsApi";

const REVALIDATE_IN_SECONDS = 60 * 60 * 6;

type Props = {
  pageFields: Record<string, never>
};

const IndexPage: FC<Props> = ({
  pageFields,
}) => (
  <>
    <Head
      withBaseSocialMetaTags
      title={pageFields.meta_title}
      description={pageFields.meta_description}
    >
    </Head>
    <HomeHeroFirst data={pageFields}/>
  </>
);

export const getStaticProps: GetStaticProps = async () => {
  try {
    const [
      pageFields,
    ] = await Promise.all([
      CmsApi.getPageFieldsFromApi('home_page')
    ]);

    return {
      revalidate: REVALIDATE_IN_SECONDS,
      props: {
        pageFields
      },
    };
  } catch (error) {
    const { message, notFound } = error as RequestError;

    if (notFound) {
      return {
        notFound: true,
      };
    }

    throw new Error(message);
  }
};

export default IndexPage;
